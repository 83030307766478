export default {
  listado: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Auth/Login'),
    },
    {
      path: '/',
      name: 'Principal',
      component: () => import('@/layouts/Container'),
      redirect: { name: 'Inicio' },
      meta: { requiresAuth: true },
      children: [
        {
          path: 'dashboard',
          name: 'Inicio',
          component: () => import('@/views/Dashboard/Index'),
        },
        {
          path: 'acreditacion',
          meta: { label: 'Acreditacion' },
          component: {
            render(c) {
              return c('router-view')
            },
          },
          children: [
            {
              path: '',
              name: 'Acreditacion',
              component: () => import('@/views/Dashboard/Acreditacion'),
            },
            {
              path: 'entrada',
              name: 'Entradas',
              component: () => import('@/views/Registro/Entrada'),
            },
            {
              path: 'salida',
              name: 'Salidas',
              component: () => import('@/views/Registro/Salida'),
            },
            {
              path: 'entradaqr',
              name: 'EntradasQR',
              component: () => import('@/views/Registro/EntradaQR'),
            },
            {
              path: 'salidaqr',
              name: 'SalidasQR',
              component: () => import('@/views/Registro/SalidaQR'),
            },
            {
              path: 'alumnos',
              name: 'Alumnos',
              component: () => import('@/views/Alumnos/TablaAlumnos'),
            },
            {
              path: 'registros',
              name: 'Registros',
              component: () => import('@/views/Alumnos/TablaRegistros'),
            },
          ],
        },

        {
          path: 'changepass',
          name: 'changepass',
          component: () => import('@/views/Auth/ChangePass'),
        },
      ],
    },
    {
      path: '*',
      component: () => import('@/views/System/PageNotFound'),
    },
  ],
}
